import React from "react";
import asti1 from "./img/dogs/asti/asti1.jpg";
import asti2 from "./img/dogs/asti/asti2.jpg";
import asti3 from "./img/dogs/asti/asti3.jpg";
import asti4 from "./img/dogs/asti/asti4.jpg";
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Asti extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="asti mb-30 col-sm-12">
                        <h3>Асти</h3>
                    </div>
                    <div className="asti mb-30 col-sm-3">
                        <img className="asti" src={asti1} style={{width:'100%'}} alt="asti1" />
                    </div>
                    <div className="asti mb-30 col-sm-3">
                        <img className="asti" src={asti2} style={{width:'100%'}} alt="asti2" />
                    </div>
                    <div className="asti mb-30 col-sm-3">
                        <img className="asti" src={asti3} style={{width:'100%'}} alt="asti3" />
                    </div>
                    <div className="asti mb-30 col-sm-3">
                        <img className="asti" src={asti4} style={{width:'100%'}} alt="asti4" />
                    </div>
                    <div className="asti mb-30 col-sm-12">
                        <h6>Возраст: 1,5 года.</h6>
                        <h6>Рост: 30 см.</h6>
                        <h6>Темперамент: сангвиник.</h6>
                        <h6>Психотип: экстраверт.</h6>
                        <p>
                            Миниатюрный вариант востоевропейской овчарки.
                        </p>
                        <p>
                            Нежная и кроткая со своим человеком, активная и игривая с сородичами, общительная, милая и любознательная девочка.
                        </p>
                        <p>
                            Идеальный вариант для собаки в квартиру. Любимого размера - по колено.
                        </p>
                        <p>
                            В силу возраста - пластилин, в плане обучения. Очень умная и сообразительная, схватывает на лету.
                        </p>
                        <p>
                            Станет Вам верным другом и соратником. Поддержит активные инициативы и спокойное времяпрепровождения.
                        </p>
                        <p>
                            Асти здорова, привита, стирилизована, обработана от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.
                        </p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Асти, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма её имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}