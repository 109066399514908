import React from 'react';
import {Link} from "react-router-dom";
import GR2017 from './reports/gov_report_2017.pdf';
import GR2018 from './reports/gov_report_2018.pdf';
import GR2019 from './reports/gov_report_2019.pdf';
import GR2020 from './reports/gov_report_2020.pdf';
import GR2021 from './reports/gov_report_2021.pdf';
import GR2022 from './reports/gov_report_2022.pdf';
import GR2023 from './reports/gov_report_2023.pdf';
import YR2020 from './reports/year_report_2020.pdf';
import YR2021 from './reports/year_report_2021.pdf';
import YR2022 from './reports/year_report_2022.pdf';
import YR2023 from './reports/year_report_2023.pdf';
import FR052020 from './reports/2020_05_financial_report.pdf';
import FR062020 from './reports/2020_06_financial_report.pdf';
import FR082020 from './reports/2020_08_financial_report.pdf';
import FR092020 from './reports/2020_09_financial_report.pdf';
import FR102020 from './reports/2020_10_financial_report.pdf';
import FR112020 from './reports/2020_11_financial_report.pdf';
import FR122020 from './reports/2020_12_financial_report.pdf';
import FR012021 from './reports/2021_01_financial_report.pdf';
import FR022021 from './reports/2021_02_financial_report.pdf';
import FR032021 from './reports/2021_03_financial_report.pdf';
import FR042021 from './reports/2021_04_financial_report.pdf';
import FR052021 from './reports/2021_05_financial_report.pdf';
import FR062021 from './reports/2021_06_financial_report.pdf';
import FR072021 from './reports/2021_07_financial_report.pdf';
import FR082021 from './reports/2021_08_financial_report.pdf';
import FR092021 from './reports/2021_09_financial_report.pdf';
import FR102021 from './reports/2021_10_financial_report.pdf';
import FR112021 from './reports/2021_11_financial_report.pdf';
import FR122021 from './reports/2021_12_financial_report.pdf';
import FR012022 from './reports/2022_01_financial_report.pdf';
import FR022022 from './reports/2022_02_financial_report.pdf';
import FR032022 from './reports/2022_03_financial_report.pdf';
import FR042022 from './reports/2022_04_financial_report.pdf';
import FR052022 from './reports/2022_05_financial_report.pdf';
import FR062022 from './reports/2022_06_financial_report.pdf';
import FR072022 from './reports/2022_07_financial_report.pdf';
import FR082022 from './reports/2022_08_financial_report.pdf';
import FR092022 from './reports/2022_09_financial_report.pdf';
import FR102022 from './reports/2022_10_financial_report.pdf';
import FR112022 from './reports/2022_11_financial_report.pdf';
import FR122022 from './reports/2022_12_financial_report.pdf';
import FR012023 from './reports/2023_01_financial_report.pdf';
import FR022023 from './reports/2023_02_financial_report.pdf';
import FR032023 from './reports/2023_03_financial_report.pdf';
import FR042023 from './reports/2023_04_financial_report.pdf';
import FR052023 from './reports/2023_05_financial_report.pdf';
import FR062023 from './reports/2023_06_financial_report.pdf';
import FR072023 from './reports/2023_07_financial_report.pdf';
import FR082023 from './reports/2023_08_financial_report.pdf';
import FR092023 from './reports/2023_09_financial_report.pdf';
import FR102023 from './reports/2023_10_financial_report.pdf';
import FR112023 from './reports/2023_11_financial_report.pdf';
import FR122023 from './reports/2023_12_financial_report.pdf';

export class Reports extends React.Component{
    render(){
        return(
                <div className="container-fluid mt-100 mb-50 text-center d-flex" >
                    <div className="flex-row justify-content-center px-3 m-auto" >
                        <div className="reports mb-10 col-sm-12" >
                            <h2>Отчёты</h2>
                            <h6>2023</h6>
                            <li>Пристроили в заботливые семьи: собак Бенитона, Тину, Клайда и котеечек Лэсси, Зевс, Руфус, Кисель, Амунет, Василий;</li>
                            <li>Спасли и взяли под опеку: собаку Мэлоди, кошку Яру, кота Имбиря, кота Рафаэля, 9 щенков с промзоны, котёнка Вегаса, котёнка Амунет, кошку Флору (Фиону), кота Персика, котёнка Бонуса;</li>
                            <li>Помогали в стерилизации кошек с шиномонтажа;</li>
                            <li>Боролись против умерщвления бездомных животных;</li>
                            <li>Пристраивали 22 котёнка: из коробок на почте и шиномонтажа;</li>
                            <li>
                                Вели просветительскую деятельность, рассказывая про: <br />
                                - корпоративную помощь; <br />
                                - движение «Приюти, не покупай»; <br />
                                - причины появления бездомных животных; <br />
                                - неделю «Имейте сострадание к собакам на цепи»; <br />
                                - комфортное содержание собак в вольере; <br />
                                - как безопасно для второго питомца взять животное из приюта; <br />
                                - домашнюю адаптацию животного из приюта с помощью зоопсихолога; <br />
                                - важность прогулок с собакой и как разгулять домоседа; <br />
                                - нечистоплотность животных, причины этого отклонения и пути решения; <br />
                                - отучение точить когти в неположенных местах; <br />
                                - отучение от покусываний во время контакта с животным; <br />
                                - перевозбуждение животного и способах успокаиваться; <br />
                                - сепарационную тревогу и пути решения; <br />
                                - работу с дестроем при адаптации в новом доме; <br />
                                - сложности выбора питомца из приюта; <br />
                                - уборку за собакой на улице; <br />
                                - причины помогать приютам для животных;  <br />
                                - стерилизацию и кастрацию; <br />
                                - важность регулярного посещения ветеринарного врача, даже когда уверен, что животное здорово; <br />
                                - гармоничные отношения детей и животных; <br />
                                - известных людей, помогающих животным; <br />
                                - процедуру взятия животного из приюта; <br />
                                - способы уберечь животное от перегрева; <br />
                                - как животные выражают свою любовь; <br />
                                - помощь вещами; <br />
                                - как стать передержкой и зачем это нужно; <br />
                                - как помочь домашним животным согреться; <br />
                                - ответственное собаководство; <br />
                                - день самой большой прогулки с домашним животным в мире; <br />
                                - день защиты животных; <br />
                                - как стать опекуном животного; <br />
                                - способ отличить мошенников от тех, кто действительно помогает; <br />
                                - Всемирный день домашних животных; <br />
                                - правила безопасности новогоднего декора; <br />
                                - правила безопасности во время салютов.
                            </li>

                            <h6>2022</h6>
                            <li>Пристроили в заботливые семьи: щенка Оливию и котеечек Чивас, Сава и Селена;</li>
                            <li>Спасли и вылечили кота Василия от ФИП;</li>
                            <li>Спасли и взяли под опеку: пёсик Нокс, кот Свэйн, кошка Плюша, пёсик Чук, кот Ричард, кот Митяй, кот Маркиза, котёнок Сава, котёнок Селена, кот Ромео, кот Обормот;</li>
                            <li>Передали более полутора тонн сухого и влажного корма для кошек и собак приютам и частным волонтёрам Москвы и Подмосковья, а также ближайших регионов;</li>
                            <li>Помогли ослице Мечте из конюшни у Тимирязевского леса;</li>
                            <li>Помогли влажным кормом и поучаствовали финансово в стерилизации кошачьего семейства  из конюшни у Тимирязевского леса;</li>
                            <li>Помогали в пристройстве кошки с тремя котятами из шиномонтажки;</li>
                            <li>Помогали в спасении и пристройстве новорожденных котят, выброшенных в коробке;</li>
                            <li>Помогали в спасении котёнка с залепленными гноем глазами;</li>
                            <li>Помогали в спасении кота, сидевшего на отбойнике в туннеле на пересечении Дмитровского шоссе и МКАД;</li>
                            <li>Вели просветительскую деятельность, рассказывая про: <br />
                                - важность и способы заботы об уличных животных; <br />
                                - волонтёрство и в каких видах помощи можно быть волонтёром;<br />
                                - интересные факты о кошках и собаках; <br />
                                - неочевидные признаки, что питомец заболел; <br />
                                - как проверить, не скрываются ли за просьбой о помощи мошенники; <br />
                                - про стереотипы о дворнягах; <br />
                                - как уберечь домашнего любимца от похищения; <br />
                                - про ФИП у кошек и успешные случаи его излечения; <br />
                                - этикет, аксессуары и безопасность на прогулках с собакой; <br />
                                - подготовку питомца к весне; <br />
                                - опекунство в зоосреде; <br />
                                - ВИК  у кошек и как с ним жить; <br />
                                - домашние передержки и зачем они нужны; <br />
                                - взаимоотношения детей и животных; <br />
                                - животных с ограниченными возможностями и жизнь с ними; <br />
                                - сетки-антикошки, спасающие кошачьи жизни; <br />
                                - проблемы с бездомными животными, откуда берутся и как помочь; <br />
                                - закон об ответственном обращении с животными; <br />
                                - людей, которым стоит воздержаться от решения завести животное; <br />
                                - людей, в жизни которых домашнее животное станет прекрасным дополнением; <br />
                                - мифы о клещах; куда деть животное на время отъезда; <br />
                                - заботу о питомце в жаркое время; <br />
                                - успешное содержание больше одного питомца в доме; <br />
                                - кошек и правильное их содержание; <br />
                                - способы пережить салют без потерь; <br />
                                - приюты для животных; <br />
                                - Всеобщую декларацию прав животного; <br />
                                - психологическую сторону помощи бездомным животным.</li>
                            <h6>2021</h6>
                            <li>Пристроили в заботливые семьи: собачек Зара, Коко, Райда, Тыковка, Льюис, Бинго, Твинки, Мерседес, Мальта и котеечек Торес, Рон, Моня, Джинкс, Бонд, Дайдай;</li>
                            <li>Помогли: кошке с онкологией, кошке Чернышке, собакам Дину и Блэку, калининградскому приюту, кошке Ките из Санкт-Петербурга;</li>
                            <li>Спасли и взяли под опеку: собачек Кук, Макки, Рассел, Льюис, Бинго, Бонго, Твинки, Блэк и котеечек Лэсси, Рон, Лея, Барсик, Черныш, Матильда, Василий;</li>
                            <li>Поддержали курс АНО "Зоркие сердца" по адаптации и социализации собак муниципальных приютов "Дубовая роща" и "Красная сосна";</li>
                            <li>Передали более 1 тонны кормов и лекарств для кошек и собак частным волонтёрам районов Москвы и Подмосковья, а также ближайших регионов.</li>
                            <h6>2020</h6>
                            <li>Февраль 2020. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <li>Август 2020. Программа стерилизации и вакцинации бездомных собак с последующем выпуском. Результат: 2 собаки;</li>
                            <li>Сентябрь 2020. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <h6>2019</h6>
                            <li>Февраль 2019. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <li>Апрель 2019. Программа стерилизации и вакцинации бездомных кошек с последующем выпуском. Результат: 7 кошек;</li>
                            <li>Сентябрь 2019. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <h6>2018</h6>
                            <li>Сентябрь 2018. Передача корма и лекарственных препаратов волонтёрам-пенсионерам, частным приютам, частным опекунам;</li>
                            <p><b>В совокупности со дня основания фонда было собрано и передано волонтёрам-пенсионерам, частным и муниципальным приютам, а также частным опекунам: <br /> более 5,5 тонны корма и более 2000 наименований лекарств</b> </p>
                        </div>
                        <div className="month-reports ml-5">
                            <h4>Ежемесячные отчеты</h4>
                            <p>
                                Если Вы не нашли свое пожертвование в отчёте, пожалуйста, свяжитесь с нами: <Link to="/contacts">Контакты</Link>
                            </p>
                        </div>
                        <div className="month-reports-2022 ml-5">
                            <h6>2023</h6>
                            <p>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR122023}>Финансовый отчёт за декабрь 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR112023}>Финансовый отчёт за ноябрь 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR102023}>Финансовый отчёт за октябрь 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR092023}>Финансовый отчёт за сентябрь 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR082023}>Финансовый отчёт за август 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR072023}>Финансовый отчёт за июль 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR062023}>Финансовый отчёт за июнь 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR052023}>Финансовый отчёт за май 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR042023}>Финансовый отчёт за апрель 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR032023}>Финансовый отчёт за март 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR022023}>Финансовый отчёт за февраль 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR012023}>Финансовый отчёт за январь 2023</a>
                                </ul>
                            </p>
                            <h6>2022</h6>
                            <p>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR122022}>Финансовый отчёт за декабрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR112022}>Финансовый отчёт за ноябрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR102022}>Финансовый отчёт за октябрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR092022}>Финансовый отчёт за сентябрь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR082022}>Финансовый отчёт за август 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR072022}>Финансовый отчёт за июль 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR062022}>Финансовый отчёт за июнь 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR052022}>Финансовый отчёт за май 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR042022}>Финансовый отчёт за апрель 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR032022}>Финансовый отчёт за март 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR022022}>Финансовый отчёт за февраль 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={FR012022}>Финансовый отчёт за январь 2022</a>
                                </ul>
                            </p>
                            </div>
                            <div className="month-reports-2021 ml-5">
                                <h6>2021</h6>
                                <p>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR122021}>Финансовый отчёт за декабрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR112021}>Финансовый отчёт за ноябрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR102021}>Финансовый отчёт за октябрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR092021}>Финансовый отчёт за сентябрь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR082021}>Финансовый отчёт за август 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR072021}>Финансовый отчёт за июль 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR062021}>Финансовый отчёт за июнь 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR052021}>Финансовый отчёт за май 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR042021}>Финансовый отчёт за апрель 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR032021}>Финансовый отчёт за март 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR022021}>Финансовый отчёт за февраль 2021</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR012021}>Финансовый отчёт за январь 2021</a>
                                    </ul>
                                </p>
                            </div>
                            <div className="month-reports-2020 ml-5">
                                <h6>2020</h6>
                                <p>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR122020}>Финансовый отчёт за декабрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR112020}>Финансовый отчёт за ноябрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR102020}>Финансовый отчёт за октябрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR092020}>Финансовый отчёт за сентябрь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR082020}>Финансовый отчёт за август 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR062020}>Финансовый отчёт за июль 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR062020}>Финансовый отчёт за июнь 2020</a>
                                    </ul>
                                    <ul>
                                        <a target="_blank" rel="noreferrer" href={FR052020}>Финансовый отчёт за май 2020</a>
                                    </ul>
                                </p>
                            </div>

                        <div className="year-reports" >
                            <h4>Годовые отчеты</h4>
                            <p>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2023}>Годовой отчёт за 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2022}>Годовой отчёт за 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2021}>Годовой отчёт за 2021</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={YR2020}>Годовой отчёт за 2020</a>
                                </ul>
                            </p>
                        </div>
                        <div className="gov-reports" >
                            <h4>Отчёты в Министерство юстиции РФ</h4>
                            <p>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2023}>Отчёт в Минюст за 2023</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2022}>Отчёт в Минюст за 2022</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2021}>Отчёт в Минюст за 2021</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2020}>Отчёт в Минюст за 2020</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2019}>Отчёт в Минюст за 2019</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2018}>Отчёт в Минюст за 2018</a>
                                </ul>
                                <ul>
                                    <a target="_blank" rel="noreferrer" href={GR2017}>Отчёт в Минюст за 2017</a>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
        )
    }
}