import React from "react";
import bianca1 from "./img/dogs/bianca/bianca1.JPG";
import bianca2 from "./img/dogs/bianca/bianca2.JPG";
import bianca3 from "./img/dogs/bianca/bianca3.JPG";
import bianca4 from "./img/dogs/bianca/bianca4.JPG";
import DogsAgremment from "./documents/agreement_dogs.pdf";
import DogsQ from "./documents/questionnaire_dogs.docx";

export class Bianca extends React.Component{
    render() {
        return(
            <div className="container-fluid mt-100 mb-50 text-center d-flex">
                <div className="row justify-content-center m-auto">
                    <div className="bianca mb-30 col-sm-12">
                        <h3>Бьянка</h3>
                    </div>
                    <div className="bianca mb-30 col-sm-3">
                        <img className="bianca" src={bianca1} style={{width:'100%'}} alt="bianca1" />
                    </div>
                    <div className="bianca mb-30 col-sm-3">
                        <img className="bianca" src={bianca2} style={{width:'100%'}} alt="bianca2" />
                    </div>
                    <div className="bianca mb-30 col-sm-3">
                        <img className="bianca" src={bianca3} style={{width:'100%'}} alt="bianca3" />
                    </div>
                    <div className="bianca mb-30 col-sm-3">
                        <img className="bianca" src={bianca4} style={{width:'100%'}} alt="bianca4" />
                    </div>
                    <div className="bianca mb-30 col-sm-12">
                        <h6>Возраст: 1 год.</h6>
                        <h6>Рост: 45 см.</h6>
                        <h6>Темперамент: холерик.</h6>
                        <h6>Психотип: экстраверт.</h6>
                        <p>
                            Бьянка - кремово-белоснежная красавица!
                        </p>
                        <p>
                            Из маленького медвежонка, девочка выросла в статную миниатюрную волчицу. Любимого размера - по колено, 22 кг.
                        </p>
                        <p>
                            Нежную и ласковую, очень ориентированную на человека, с преданностью утыкающуюся в ноги и с доверием заглядывая в глаза.
                        </p>
                        <p>
                            Ее взгляд и бесконечно милый розовый нос никого не оставит равнодушным.
                        </p>
                        <p>
                            Бьянка невероятна умна, команды схватывает буквально на лету! Прекрасно ходит на поводке, без проблем ездит в машине.
                        </p>
                        <p>
                            Не проявляет агрессии к человеку от слова совсем, но может постоять за себя среди сородичей, панибратство терпеть не будет.
                        </p>
                        <p>
                            Малышка с детства в приюте. А это уже почти год. Она заждалась  своего человека, свою семью. Может это именно вы?
                        </p>
                        <p>
                            Бьянка здорова, привита, стирилизована, обработана от всех паразитов. Пристраивается по <a href={DogsAgremment} download="agreement_dogs.pdf">договору ответственного содержания</a> с ненавязчивым отслеживанием дальнейшей судьбы.
                        </p>
                    </div>
                    <div className="chivas mb-30 col-sm-12">
                        <h6><b>Если Вам понравилась Бьянка, Вы можете заполнить <a href={DogsQ} download="questionnaire_dogs.docx">Анкету потенциального хозяина</a> и после направить нам её на e-mail <a href="mailto:info@budu-ryadom.ru">info@budu-ryadom.ru</a> с указанием в теме письма её имени</b></h6>
                    </div>
                </div>
            </div>
        )
    }
}